import request from "@/utils/request";

export function StockInReportList(params) {
  return request({ url: `/statistics/stock_in_reports/`, method: "get", params });
}

export function StockInReportExport(params) {
  return request({
    url: "/statistics/stock_in_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function StockOutReportList(params) {
  return request({ url: `/statistics/stock_out_reports/`, method: "get", params });
}

export function StockOutReportExport(params) {
  return request({
    url: "/statistics/stock_out_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function deliveryRecordReportList(params) {
  return request({ url: `/statistics/delivery_record_reports/`, method: "get", params });
}

export function deliveryRecordReportExport(params) {
  return request({
    url: "/statistics/delivery_record_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function materialTopTenList(params) {
  return request({ url: `/statistics/material_top_ten/`, method: "get", params });
}

export function clientProportionList(params) {
  return request({ url: `/statistics/client_proportion/`, method: "get", params });
}

export function storeInfoList(params) {
  return request({ url: `/statistics/store_info/`, method: "get", params });
}

export function stockAgeStats(params) {
  return request({ url: `/statistics/stock_age_stats/`, method: "get", params });
}
